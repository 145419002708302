import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { Fade } from 'react-slideshow-image'

const fadeProperties = {
  duration: 6000,
  transitionDuration: 800,
  infinite: true,
  indicators: false,
}

const StyledDiv = styled.div`
  .react-slideshow-container .nav {
    display: none;
  }
  .react-slideshow-fade-wrapper {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    z-index: -1;
    img {
      object-fit: cover;
      height: 100%;
      object-position: 50% 50%;
    }
  }
`

const SliderHeader = (props) => {

  const mappedHeaders = props.sliderImages.map((el,i) => (
    <div key={`slider-header-${i}`}>
      <Img
        fluid={el.fluid}
        key={`header-image-${i}`}
        objectFit="cover"
        objectPosition="50% 50%"
        style={{height: '40vh'}}
        fadeIn
        loading={'auto'}
        imgStyle={{
          height: '100%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
        }}
      />
    </div>
  ))

  return (
    <StyledDiv className="slide-container">
      <Fade {...fadeProperties}>
        { mappedHeaders }
      </Fade>
    </StyledDiv>
  )
}

export default SliderHeader