import theme from '../styles/theme'

// eslint-disable-next-line import/prefer-default-export
export const particleConfigWhite = {
  particles: {
    number: {
      value: 58,
      density: {
        enable: true,
        value_area: 1362.9002517356944,
      },
    },
    color: {
      // value: theme.colors.highlight,
      value: 'transparent',
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: true,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1122.388442605866,
      },
    },
    line_linked: {
      enable: true,
      distance: 272.58005034713887,
      opacity: 0.8,
      color: {
        value: theme.colors.tertiary,
      },
      shadow: {
        enable: false,
        color: theme.colors.secondary,
        blur: 3,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      // onHover: {
      //   enable: true,
      //   mode: 'repulse',
      // },
      resize: true,
    },
  },
  retina_detect: true,
  fullScreen: {
    enable: false,
  },
}
