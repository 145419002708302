import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Container from './Container'
import SliderHeader from './Slider.Header'
// import Loadable from 'react-loadable'

import { particleConfigWhite } from '../utils/particles-white'

import loadable from '@loadable/component'
const ParticleComponent = loadable(() => import('react-tsparticles'))

const Title = styled.h1`
  text-align: left;
  position: absolute;
  font-weight: 700;
  font-size: 3.5em;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 3em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 1.5em;
  }
  font-family: ${props => props.theme.fonts.title};
  color: ${props => props.theme.colors.secondary};
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.5);
  &.inverted {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    color: ${props => props.theme.colors.inverted};
  }
  &.no-margin {
    margin: 0;
  }
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.secondary};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const SubTitle = styled.h2`
  font-family: ${props => props.theme.fonts.title};
  font-weight: 800;
  margin: ${props => (props.small ? '1rem 0 4rem 0' : '0 0 3rem 0')};
  color: ${props => props.theme.colors.secondary};
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.5);
  &.inverted {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    color: ${props => props.theme.colors.inverted};
  }
  font-size: 2.2em;
  line-height: 1.4;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 2em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 1.1em;
  }
`

const HeaderImageContainer = styled.div`
  width: 100vw;
  height: 40vh;
  max-height: 400px;
  background-size: cover;
  overflow-y: hidden;
  z-index: -1;
  position: relative;
  border-top: 0.25rem solid ${props => props.theme.colors.highlight};
  border-bottom: 0.25rem solid ${props => props.theme.colors.secondary};
`

const imgWrapperStyles = {
  position: 'absolute',
  overflow: 'hidden',
  width: '100%',
  zIndex: '-1',
  height: '100%',
}

const PageTitle = props => {
  const imgStyles = {
    ...props.imgStyles,
  }

  return (
    <HeaderImageContainer
      style={{
        backgroundColor: props.backgroundColor || 'transparent',
        zIndex: props.isPost ? -1 : 333,
      }}
    >
      {props.particles && typeof window !== 'undefined' && (
        <ParticleComponent
          width="100%"
          key={Math.random()}
          id={'particle-wrapper-header'}
          params={particleConfigWhite}
          style={{
            opacity: 1,
            backgroundColor: 'transparent',
            position: 'absolute',
            width: '100%',
            height: 'auto',
            top: 0,
            canvasClassName: 'particle-canvas',
            // zIndex: 999,
          }}
          fullScreen={false}
        />
      )}
      {props.background && !props.sliderImages && (
        <Img
          fluid={props.background.fluid}
          loading={'eager'}
          placeholderStyle={
            {
              // opacity: 0
            }
          }
          objectFit={props.objectFit || 'cover'}
          objectPosition="50% 50%"
          style={{
            height: '40vh',
            ...imgWrapperStyles,
          }}
          imgStyle={{
            height: '100%',
            objectFit: props.objectFit || 'cover',
            objectPosition: '50% 50%',
            ...imgStyles,
          }}
        />
      )}
      {props.sliderImages && <SliderHeader sliderImages={props.sliderImages} />}
      <Container>
        {props.title && (
          <Title
            className={`${props.noMargin ? 'no-margin' : ''} ${
              props.inverted ? 'inverted' : ''
            }`}
            small={props.small}
          >
            {props.title}
          </Title>
        )}
        {props.subTitle && (
          <SubTitle className={`${props.inverted ? 'inverted' : ''}`}>
            {props.subTitle}
          </SubTitle>
        )}
      </Container>
      {props.children}
    </HeaderImageContainer>
  )
}

export default PageTitle
